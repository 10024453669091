<template>
  <ContentSection class="overflow-hidden bg-amber-100">
    <Container
      class="grid auto-rows-min grid-cols-1 lg:grid-cols-3 lg:grid-rows-2 5xl:grid-cols-4"
      :class="{
        'py-6 sm:py-8 md:py-10 lg:py-12 xl:py-14':
          pageId !== 'c0a53f6a-b1db-4743-8846-000e71cc87a1',
      }"
    >
      <!-- TITLE -->
      <div class="order-1 mb-16 lg:col-span-1 lg:pr-2">
        <h2 class="max-w-sm text-xl tracking-normal md:text-5xl">
          {{ data.heading }}
        </h2>

        <AppButton
          :to="data.button_link"
          aria-label="Gehe zur Übersicht aller Blog Artikel"
          class="mt-10"
        >
          {{ data.button_text }}
        </AppButton>
      </div>

      <!-- SWIPER -->
      <div
        class="relative order-2 mr-[-80%] flex h-fit sm:mr-[-60%] md:col-span-2 md:mr-[-40%] lg:row-span-2 lg:mr-[-20%] 5xl:col-span-3"
      >
        <Swiper
          v-if="Array.isArray(blogPosts) && blogPosts.length > 0"
          :breakpoints="breakpoints"
          @swiper="onSwiper"
          @reach-beginning="
            $refs.sliderButtons.reachedBeginningOrEnd('beginning')
          "
          @reach-end="$refs.sliderButtons.reachedBeginningOrEnd('end')"
          @from-edge="$refs.sliderButtons.fromEdge()"
        >
          <SwiperSlide v-for="post in blogPosts" :key="post.id">
            <EntryCard
              :class="`${post.color_theme?.value || 'blue'}-el`"
              :data="post"
              class="md:max-w-[31.25rem]"
            />
          </SwiperSlide>

          <!-- EMPTY END SLIDE -->
          <SwiperSlide />
        </Swiper>
      </div>

      <!-- ARROWS -->
      <div
        v-if="Array.isArray(blogPosts) && blogPosts.length > 0"
        class="order-3 items-center justify-end p-6 pr-14 lg:row-start-2 lg:flex"
      >
        <SliderButtons
          ref="sliderButtons"
          class="lg:translate-y-[calc(-50%-1.75rem)]"
          @prev="swiper.slidePrev()"
          @next="swiper.slideNext()"
        />
      </div>
    </Container>
  </ContentSection>
</template>

<script setup>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
import { store } from '/store.js'

// Import Swiper styles
import 'swiper/css'
const props = defineProps({
  data: { type: Object, required: true },
})

const isSimpleLanguage = store?.isSimpleLanguage ?? false
const site = isSimpleLanguage ? 'de-plain' : 'default'

watch(
  () => props.data,
  () => {
    populateBlogPosts()
  },
  { deep: true }
)

let blogPosts = ref([])

async function fetchBlogAndCategories() {
  const response = await useAsyncGql({
    operation: 'BlogAndCategories',
    variables: {
      limit: parseInt(props.data.amount_blog_posts || 1),
      site: site,
      whereNotPageId: store.pageId,
    },
  })

  blogPosts.value = response.data?.value?.entries.data
}

async function populateBlogPosts() {
  if (props.data.load_automatically) {
    await fetchBlogAndCategories()
  } else {
    blogPosts.value = props.data.blog_posts || []
  }
}

await populateBlogPosts()

// COMPUTED
const pageId = computed(() => {
  return store.pageId
})

// SWIPER

let swiper = {}

const onSwiper = (swiperElement) => {
  swiper = swiperElement
}

const breakpoints = ref({
  0: {
    slidesPerView: Math.min(2, blogPosts.value?.length + 1 || 1),
    spaceBetween: 32,
  },
  768: {
    // md
    slidesPerView: Math.min(2, blogPosts.value?.length + 1 || 1),
    spaceBetween: 40,
  },
  1024: {
    // lg
    slidesPerView: Math.min(2, blogPosts.value?.length + 1 || 1),
    spaceBetween: 56,
  },
  1536: {
    // 2xl
    slidesPerView: Math.min(2, blogPosts.value?.length + 1 || 1),
    spaceBetween: 56,
  },
  1720: {
    // 2xl
    slidesPerView: Math.min(3, blogPosts.value?.length + 1 || 1),
    spaceBetween: 56,
  },
  2240: {
    slidesPerView: Math.min(4, blogPosts.value?.length + 1 || 1),
    spaceBetween: 56,
  },
  2920: {
    slidesPerView: Math.min(5, blogPosts.value?.length + 1 || 1),
    spaceBetween: 56,
  },
  3920: {
    slidesPerView: Math.min(6, blogPosts.value?.length + 1 || 1),
    spaceBetween: 56,
  },
})
</script>
